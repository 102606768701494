import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/Breadcrumb'
import Arrow from '../components/Arrow'

import { Wrapper, Content, Boom, getImage } from '../templates/page'
import { Sidebar } from '../components/Sidebar'

export default ({ data }) => {
  const { title, content, acf, featured_media } = data.wordpressPage

  return (
    <Layout>
      <SEO title="Contact Us" />
      <Boom backgroundImage={getImage(featured_media)}>
        <div className="overlay">
          <div className="inner">
            <h1>{title}</h1>
          </div>
        </div>
      </Boom>

      <Breadcrumb>
        <Link to="/">Home</Link> <Arrow /> <b>{title}</b>
      </Breadcrumb>

      <Wrapper>
        <Content
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />

        <Sidebar dangerouslySetInnerHTML={{ __html: acf.sidebar }} />
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "contact" }) {
      title
      content
      acf {
        sidebar
      }
      date(formatString: "MMMM DD, YYYY")
      slug
    }
  }
`
