import React from 'react'
import styled from 'styled-components'

const StyledSidebar = styled.div`
  margin-left: 1rem;
  font-size: 16px;
  h1,
  h3 {
    font-size: 18px;
  }
`

export const Sidebar = props => {
  return <StyledSidebar {...props} />
}
